angular.module("mfgApp").factory('mfgApi', ["$http","Upload", function ($http, Upload) {

    function saveRogueCarrier(request) {
        return $http.post("/admin/rogueUnit", request);
    }

    function deleteRogueCarrier(serial) {
        return $http['delete']("/admin/rogueUnit/" + serial);
    }

    function getLicenseTerms() {
        return $http.get("/admin/license/licenseTerms");
    }

    function exportLicenses(ids) {
    	var request={
    		ids:ids
    	};
    	
        return $http.post("/organization/license/export", request);
    }

    function downloadLicenses(exportUrl) {
        return $http.get(exportUrl);
    }
    
    function getAllRogueUnits() {
        return $http.get("/admin/rogueUnits");
    }

    function getAllAFLAssetProductCodes() {
        return $http.get("/admin/AFLAssetProductCode");
    }

	function uploadRogueImport(file){
		  return Upload.upload({
            url: '/admin/api/upload-rogue-xls',
            fields: {},
            file: file
        });
	}

    function uploadProductCodeImport(file){
        return Upload.upload({
            url: '/admin/api/upload-productCode-xls',
            fields: {},
            file: file
        });
    }

    function generateLicenses(numLicenses, generateModel) {

        var refactoredGenerateModel = {};
        angular.copy(generateModel, refactoredGenerateModel);
        refactoredGenerateModel.term = refactoredGenerateModel.licenseType.name;
        delete refactoredGenerateModel.licenseType;

        return $http.post("/admin/license/generate/" + numLicenses, refactoredGenerateModel);
    }


    function getFilteredLicensesList (config) {
    	var url = '/admin/license/search';
    	return $http.post(url, config);
    }

    return {
        getLicenseTerms: getLicenseTerms,
        saveRogueCarrier: saveRogueCarrier,
        deleteRogueCarrier: deleteRogueCarrier,
        getAllRogueUnits: getAllRogueUnits,
        getAllAFLAssetProductCodes: getAllAFLAssetProductCodes,
        generateLicenses: generateLicenses,
        uploadRogueImport:uploadRogueImport,
        exportLicenses: exportLicenses,
        downloadLicenses: downloadLicenses,
        getFilteredLicensesList: getFilteredLicensesList,
        uploadProductCodeImport: uploadProductCodeImport
    };
}]);
